.navbar {
	position: fixed;
	background: white;
	bottom: 0;
	display: flex;
	justify-content: right;
	width: 100%;
	height: 10vh;
	color: black;
	z-index: 120;
}

.navbar > p {
	display: none;
}

.nav {
	background-color: #592c54;
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	padding: 0;
	align-items: center;
	justify-content: space-around;
}

.nav > li {
	list-style: none;
}

.nav > li > a {
	text-decoration-line: none;
}

.mobile-element {
	display: block;
	font-size: 25px;
	fill: #fff;
}

.desktop-element {
	display: none;
}

@media (min-width: 1024px) {
	.navbar {
		justify-content: space-between;
		padding: 0 3rem;
	}
	.navbar > p {
		display: block;
		align-self: center;
		font-family: "Oleo Script", cursive;
		font-size: 30px;
	}

	.nav {
		width: 80%;
		column-gap: 4rem;
		justify-content: end;
		background-color: #fff;
	}

	.navbar {
		top: 0;
		bottom: unset;
	}

	.mobile-element {
		display: none;
	}
	.desktop-element {
		display: block;
	}
}
