* {
	margin: 0px;
	color: #1e1431;
	box-sizing: border-box;
}

:root {
	--font-size-small: 18px;
	--font-size-medium: 20px;
	--font-size-large: 40px;
	--font-size-xlarge: 45px;
	--primary-color: #1e1431;
	--secondary-color: #a2729017;
	--primary-font: "Oleo Script", cursive;
	--secondary-font: "Roboto Condensed", sans-serif;
}

/* Text elements */

h1 {
	font-family: var(--primary-font);
}

.section-title {
	font-size: var(--font-size-large);
}

h2 {
	font-family: var(--primary-font);
	font-size: var(--font-size-large);
}

p,
a,
label {
	font-size: var(--font-size-small);
	font-family: var(--secondary-font);
	line-height: 30px;
}

img {
	max-width: 100%;
}

.fade-in {
	animation: 2s 1 fadeIn;
}

.secondary-background {
	background: #a2729017;
}

.neumorphic {
	border-radius: 20px;
	box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
}

.button {
	background-color: transparent;
	width: auto;
	padding: 0 2rem;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	font-size: var(--font-size-medium);
	border: none;
}

.button:hover {
	transform: translateX(15px);
}

.link {
	text-decoration: none;
	font-size: var(--font-size-medium);
	font-weight: bold;
}

.home-subtitle {
	width: 100%;
	height: 25vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.dev-social-media {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 2rem;
}

.social-media-container {
	display: flex;
	column-gap: 1rem;
	align-items: center;
	justify-content: center;
}

.social-media-icon {
	height: 2rem;
}
.social-media-icon > img {
	width: 100%;
	height: 100%;
}

.about-container {
	display: grid;
	height: auto;
	grid-template-rows: repeat(2, auto);
	padding-top: 10vh;
}

.about-me {
	display: flex;
	flex-direction: column;
	width: 90%;
	justify-self: center;
	margin-bottom: 5%;
}

.about-me > h1 {
	margin: 10% 0 5% 0;
	padding: 0 20% 0 20%;
	text-align: center;
}

.about-me-subtitle {
	text-align: center;
}

.about-me > p {
	margin: 0 0 5% 0;
}

.about-me > img {
	width: 70%;
	align-self: center;
	margin-bottom: 5%;
}

@media (min-width: 768px) {
	.section-title {
		font-size: var(--font-size-xlarge);
	}

	h2 {
		font-size: var(--font-size-xlarge);
	}

	p,
	a {
		font-size: var(--font-size-medium);
		line-height: 35px;
	}

	.about-me {
		width: 60%;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: scale(0.8);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
