.contact-wrapper {
	padding-bottom: 10vh;
}

.contact-section {
	width: 100%;
	height: 90vh;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
}

.contact-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-area: 1/1/2/2;
	justify-content: center;
	padding: 1rem 5vh;
	row-gap: 3vh;
	width: 100%;
	height: 100%;
}
.contact-image-container {
	display: none;
}

@media (min-width: 1024px) {
	.contact-wrapper {
		padding-bottom: 0;
	}

	.contact-section {
		height: 100vh;
		grid-template-columns: 60% 40%;
	}

	.contact-container {
		padding: 12vh 10vw 5vh 10vw;
		row-gap: 1.5rem;
	}

	.contact-image-container {
		width: 100%;
		height: 100%;
		opacity: 0.7;
		display: grid;
		grid-area: 1/2/2/3;
		grid-template-columns: 100%;
		grid-template-rows: 100%;
	}

	.sky {
		width: 100%;
		height: 100%;
		object-fit: cover;
		grid-area: 1/1/2/2;
	}

	.woman {
		grid-area: 1/1/2/2;
		height: 70%;
		position: absolute;
		bottom: 0;
		right: 11vw;
	}
}
