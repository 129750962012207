.card {
	width: 320px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: 0.5rem;
	padding: 1rem;
}

.card-title {
	font-family: var(--primary-font);
	font-size: var(--font-size-medium);
}

.card-description {
	font-size: 16px;
	line-height: 23px;
	margin-top: 0.5rem;
}

.card-image {
	width: 100px;
	height: 100px;
	box-shadow: inset 2px 2px 5px #bcb8be, inset -3px -3px 7px #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
}

.card-image > img {
	width: 80%;
	height: 80%;
	object-fit: cover;
	border-radius: 8px;
}

.card-buttons-container {
	display: flex;
	justify-content: space-between;
}

.card-button {
	padding: 0 2rem;
	font-size: 14px;
	background: transparent;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	text-decoration: none;
}

.card-button:hover {
	box-shadow: inset 2px 2px 5px #bcb8be, inset -3px -3px 7px #fff;
}

.card-tools-container {
	display: flex;
	align-items: center;
	font-size: 16px;
	column-gap: 1rem;
}

.tools {
	color: var(--primary-color);
}
