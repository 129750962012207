.tools-main-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	row-gap: 4rem;
	padding-bottom: 4rem;
}

.tools-container {
	width: 85%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-items: center;
	row-gap: 2rem;
	box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
	border-radius: 10px;
	padding: 1rem;
}

.tool-icon {
	font-size: 2rem;
	display: grid;
	align-items: center;
	justify-items: center;
	row-gap: 1rem;
	text-align: center;
}

@media (min-width: 768px) {
	.tools-container {
		width: 60%;
		padding: 4rem 0;
	}
	.tool-icon {
		font-size: 3rem;
	}
}
