.main-container {
	display: flex;
	flex-direction: column;
	width: 100vw;
}

.home {
	height: auto;
	display: grid;
	background: #ad9c9c21;
	padding-bottom: 10vh;
}

.home-hero-container {
	width: 100%;
	height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;
	background: url("../../assets/intro.jpg") no-repeat;
	background-size: cover;
	position: fixed;
	z-index: -100;
}

.home-hero-container > h1 {
	font-size: var(--font-size-xlarge);
}

.light-color-text {
	color: #a27290;
}

.no-pointer-events {
	position: relative;
	z-index: 110;
	width: 100%;
	height: 90vh;
	opacity: 1;
	pointer-events: none;
}

.scrollable {
	position: relative;
	background-color: white;
	height: auto;
}

.home-welcome-container {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 1rem;
	background-color: var(--secondary-color);
	padding: 4rem 2rem 0 2rem;
}

.home-welcome-container > p {
	line-height: 2em;
}

.home-subtitle > h2 {
	text-align: center;
}

.home-codepens-container {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	padding: 4rem 2rem;
	row-gap: 3rem;
	align-items: center;
}

.home-codepens-container > .button {
	grid-column: 1/3;
	text-decoration-line: none;
}

@media (min-width: 1024px) {
	.home {
		padding-top: 10vh;
		padding-bottom: 0;
	}

	.main-container {
		flex-direction: row;
	}

	.home-hero-container {
		height: 80vh;
	}

	.home-hero-container > h1 {
		font-size: 6em;
	}

	.no-pointer-events {
		height: 90vh;
	}

	.home-welcome-container {
		padding: 0 4rem;
		width: 50%;
	}

	.home-codepens-container {
		width: 50%;
		padding: 4rem;
	}
}
