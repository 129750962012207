input {
	background-color: transparent;
	border: 0.0625rem solid #dad1e6;
	border-radius: 8px;
	box-shadow: inset 2px 2px 5px #bcb8be, inset -3px -3px 7px #fff;
	height: 3rem;
	width: 100%;
	padding: 0 1rem;
	font-family: var(--secondary-font);
	font-size: 1rem;
}

textarea {
	background-color: transparent;
	border: 0.0625rem solid #dad1e6;
	border-radius: 8px;
	box-shadow: inset 2px 2px 5px #bcb8be, inset -3px -3px 7px #fff;
	height: 18vh;
	padding: 1rem;
	width: 100%;
	font-family: var(--secondary-font);
	font-size: 1rem;
}

.input-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	row-gap: 1rem;
}
