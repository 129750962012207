.work {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 10vh;
}

.intro-work {
	height: 40vh;
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	row-gap: 1rem;
}

.container {
	width: 100%;
	padding: 10vh 0 10vh 0;
	height: auto;
}

.cards-container {
	margin: 0 auto;
	max-width: 800px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	row-gap: 3rem;
}

@media (min-width: 1024px) {
	.work {
		padding-bottom: 0;
	}
	.intro-work {
		height: 40vh;
		width: 60%;
		padding: 20vh 0 15vh 0;
		text-align: center;
	}
	.container {
		padding: 15vh 0 15vh 0;
	}
}
